import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import Icon from '@ckeditor/ckeditor5-core/theme/icons/three-vertical-dots.svg';

import Swal from 'sweetalert2'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import TABLES from './tables';
import Choices from "choices.js";


const getReports = async(userData) => {
  const { ts } = userData.lkps_profile
  const department = userData.departments[0]
  const { program_studi, lkps_program } = department
  const { short_name } = lkps_program
  let data = [];
  try {
    const { data } = await useJwt.http.get('lkps_table', {
      params: {
        page: 0,
        size: 60,
        sort: 'ASC',
        ts,
        state: `state_${short_name.toLowerCase()}`,
        short_name,
        program_studi
      },
    })
    return data.lkps_tables;
  } catch (error) {
    Swal.fire(
      'Gagal!',
      'Tidak dapat mengambil data LKPS...',
      'error'
    )
    return false;
  }
}

class LKPSTable extends Plugin {
    init() {
        const tables = TABLES;
        const userData = getUserData();
        const editor = this.editor;
        const tableSelections = tables.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: item.name }), {})
        editor.ui.componentFactory.add('LKPSTable', locale => {
            const view = new ButtonView( locale );

            view.set( {
                label: 'Data Kuantitatif',
                tooltip: true,
                withText: true
            } );

            // Callback executed once the image is clicked.
            view.on('execute', () => {
                Swal.fire({
                  title: 'Masukkan Data Kuantitatif',
                  input: 'select',
                  width: 1000,
                  inputOptions: tableSelections,
                  inputPlaceholder: 'Pilih Data',
                  showCancelButton: true,
                  inputValidator: (value) => {
                    return new Promise((resolve) => {
                      resolve()
                    })
                  },
                  willOpen: (el) => {
                    new Choices(".swal2-select");
                  },
                  preConfirm: async(value) => {
                    Swal.showLoading()
                    const { ts } = userData.lkps_profile
                    const department = userData.departments[0]
                    const { program_studi, lkps_program } = department
                    const { short_name } = lkps_program
                    const reports = await getReports(userData);
                    if(reports) {
                      let data = []
                      let calculation = []
                      const tableId = document.querySelector('select.swal2-select option').value;
                      if(tableId !== '') {
                        const table = tables.find(table => table.id == tableId);
                        if(table.endpoint && !Array.isArray(table.name_sheet)) {
                          const lkps_table = reports.find(report => report.list_lkps_table.name_sheet === table.name_sheet)
                          const resp = await useJwt.http.get(`${table.endpoint}`, {
                            params: {
                              page: 0,
                              size: 50,
                              sort: 'ASC',
                              ts,
                              program_studi,
                              lkps_table_id: lkps_table.id,
                            }
                          })
                          data = resp.data[`${table.endpoint}s`]
                          try {
                            calculation = resp.data.calculation
                            console.log(`${calculation ? '✓ calculation' : '✕ calculation'}`)
                          } catch (err) {
                            console.log('on error :', err)
                          }
                        }

                        if(Array.isArray(table.name_sheet)) {
                          const resp = await Promise.all(table.name_sheet.map(async(name_sheet) => {
                            const lkps_table = reports.find(report => report.list_lkps_table.name_sheet === name_sheet);
                            const resp = await useJwt.http.get(`${table.endpoint}`, {
                              params: {
                                page: 0,
                                size: 50,
                                sort: 'ASC',
                                ts,
                                program_studi,
                                lkps_table_id: lkps_table.id
                              }
                            });
                            try {
                              calculation = resp.data.calculation
                              console.log(`${calculation ? '✓ calculation' : '✕ calculation'}`)
                            } catch (err) {
                              console.log('on error :', err)
                            }
                            return resp.data[`${table.endpoint}s`]
                          }))
                          data = resp
                        }
                        const element = table.formatter(data, calculation)
                        return element;
                      }
                    }
                    return false;
                  },
                }).then((result) => {
                  const { value: element } = result;
                  if (result.isConfirmed) {
                    if(element) {
                      editor.model.change(writer => {
                        const htmlDP = editor.data.processor;
                        const viewFragment = htmlDP.toView(element);
                        const modelFragment = editor.data.toModel(viewFragment);
                        // Insert the image in the current selection location.
                        editor.model.insertContent(modelFragment, editor.model.document.selection);
                      });
                    }
                  }
                })

            } );

            return view;
        });
    }
}

export default LKPSTable;