import { v4 as uuid } from 'uuid'

function createTable(header, rownum, body, footer = null, caption = null) {
  const table = `
  <figure class="table">
    <table>
      <thead>
        ${header}
        ${rownum}
      </thead>
      <tbody>
        ${body}
      </tbody>
      ${footer || '&nbsp;'}
    </table>
    ${caption || '&nbsp;'}
  </figure>`
  return table
}

function createTableRow(cols) {
  let rows = '<tr>'
  for (let index = 0; index < cols.length; index++) {
    rows += `<td><p style="text-align:center;">${cols[index]}</p></td>`
  }
  return rows
}

function createTableRows(rows, rowsEmptyCols = null) {
  let header = ''
  const data = rows
  if (rowsEmptyCols) {
    rowsEmptyCols.forEach((totalCols, totalColsIndex) => {
      const cols = new Array(totalCols).fill().map((_, i) => ({ name: '&nbsp;', rowspan: 1 }))
      data[totalColsIndex] = [
        ...data[totalColsIndex],
        ...cols,
      ]
    })
  }
  data.forEach(row => {
    const html = row
      .map(col => `
        <td colspan="${col.colspan ?? 1}" rowspan="${col.rowspan ?? 2}">
          <p style="text-align:${col.align ?? 'center'};">${col.name}</p>
        </td>
      `)
      .join('')
    header += `<tr>${html}</tr>`
  })
  return header
}

function createTableRowNum(length) {
  let rows = '<tr>'
  for (let row = 1; row <= length; row++) {
    rows += `<td><p style="text-align:center;">(${row})</p></td>`
  }
  rows += '</tr>'
  return rows
}

function createTableCaption(caption) {
  return `
    <div class="caption">
      <p>${caption}</p>
    </div>
  `
}

const tables = [
  {
    id: uuid(),
    name: 'Tabel 2.2.2 Data Kerja Sama - Bidang Pendidikan',
    name_sheet: ['2.2.2-1', '2.2.2-2', '2.2.2-3', '2.2.2-4'],
    endpoint: 'lkps_kerjasama',
    formatter: results => {
      const rownum = createTableRowNum(9)
      const header = createTableRows([
        [
          { name: 'No.' },
          { name: 'Nama Lembaga Mitra' },
          { name: 'Tingkat', colspan: 3, rowspan: 1 },
          { name: 'Manfaat/Output' },
          { name: 'Durasi dan <br />Waktu' },
          { name: 'Bukti/Tautan*' },
        ],
        [
          { name: 'Internasional', rowspan: 1 },
          { name: 'Nasional', rowspan: 1 },
          { name: 'Lokal', rowspan: 1 },
        ],
      ])
      // FIRST DATA
      const body = results.map((data, index) => {
        let dataTable = ''
        const dataHeaderName = {
          0: 'Bidang Pendidikan',
          1: 'Bidang Penelitian',
          2: 'Bidang Pengabdian kepada Masyarakat (PkM)',
          3: 'Bidang Pengembangan Kelembagaan: SDM, Sarana/Prasarana, Publikasi, HKI, Paten, Teknologi Pembelajaran, dll.',
        }[index]
        dataTable += createTableRows([
          [{
            name: dataHeaderName, colspan: 9, rowspan: 1, align: 'left',
          }],
        ])
        dataTable += data.map((d, index) => createTableRow([
          index + 1,
          d.lembaga_mitra,
          d.ting_internasional ? '✓' : '✕',
          d.ting_nasional ? '✓' : '✕',
          d.ting_wilayah ? '✓' : '✕',
          d.judul_kegiatan,
          d.manfaat_bagi_ps,
          d.wkt_durasi,
          d.bukti,
        ])).join('')
        // dataTable += createTableRows([
        //   [{ name: 'Jumlah', colspan: 2, rowspan: 1, align: 'left' }]
        // ])
        return dataTable
      }).join('')
      const caption = createTableCaption('*Contoh: Laporan pelaksanaan kerja sama')
      const table = createTable(header, rownum, body, null, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 3.1.2.2 Mahasiswa Reguler',
    name_sheet: '3.1.2.2',
    endpoint: 'lkps_mhsregular',
    formatter: (data, calc) => {
      const body = data.map((d, index) => createTableRow([
        d.tahun_akademik,
        d.daya_tampung,
        d.pendaftar,
        d.lulus_seleksi,
        d.jumlah_mahasiswa_baru_reguler,
        d.jumlah_total_mahasiswa_reguler,
      ])).join('')
      const rownum = createTableRowNum(6)
      const header = createTableRows([
        [
          { name: 'Tahun Akademik' },
          { name: 'Daya Tampung' },
          {
            name: 'Jumlah Calon Mahasiswa Reguler',
            colspan: 2,
            rowspan: 1,
          },
          { name: 'Jumlah Mahasiswa Baru <br />Reguler' },
          { name: 'Jumlah Total <br />Mahasiswa Reguler' },
        ],
        [
          { name: 'Pendaftar', rowspan: 1 },
          { name: 'Lulus Seleksi', rowspan: 1 },
        ],
      ])
      const footer = createTableRows(
        [
          [
            { name: 'Jumlah', align: 'center' },
            { name: `${calc.daya_tampung ?? '0 error'}`, align: 'center' },
            { name: `${calc.pendaftar ?? '0 error'}`, align: 'center' },
            { name: `${calc.lulus_seleksi ?? '0 error'}`, align: 'center' },
            { name: `${calc.jumlah_mahasiswa_baru_reguler ?? '0 error'}`, align: 'center' },
            { name: `${calc.jumlah_total_mahasiswa_reguler ?? '0 error'}`, align: 'center' },
          ],
        ],
        [4],
      )
      const caption = createTableCaption('*TS:Tahun akademik penuh terakhir saat pengisian instrumen')
      const table = createTable(header, rownum, body, footer, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 3.1.2.3.1 Calon Mahasiswa Dalam Negeri',
    name_sheet: '3.1.2.3.1',
    endpoint: 'lkps_cmdn',
    formatter: (data, calc) => {
      const body = data.map((d, index) => createTableRow([
        d.tahun_akademik,
        d.jml_provinsi,
        d.jcmr_l,
        d.jcmr_p,
        d.jtmr,
      ])).join('')
      const rownum = createTableRowNum(6)
      const header = createTableRows([
        [
          { name: 'Tahun Akademik' },
          { name: 'Jumlah Provinsi' },
          { name: 'Jumlah Calon Mahasiswa Regular', colspan: 2, rowspan: 1 },
          { name: 'Jumlah Total <br /> Mahasiswa Reguler' },
        ],
        [
          { name: 'Laki-laki', rowspan: 1 },
          { name: 'Perempuan', rowspan: 1 },
        ],
      ])
      const footer = createTableRows([
        [
          { name: 'Jumlah', align: 'center' },
          { name: `${calc.jml_provinsi ?? '0 error'}`, align: 'center' },
          { name: `${calc.jcmr_l ?? '0 error'}`, align: 'center' },
          { name: `${calc.jcmr_p ?? '0 error'}`, align: 'center' },
          { name: `${calc.jtmr ?? '0 error'}`, align: 'center' },
        ],
      ], [4])
      const table = createTable(header, rownum, body, footer)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 3.1.2.3.2 Calon Mahasiswa Luar Negeri',
    name_sheet: '3.1.2.3.2',
    endpoint: 'lkps_cmdn',
    formatter: (data, calc) => {
      const body = data.map((d, index) => createTableRow([
        d.tahun_akademik,
        d.jml_provinsi,
        d.jcmr_l,
        d.jcmr_p,
        d.jtmr,
      ])).join('')
      const rownum = createTableRowNum(6)
      const header = createTableRows([
        [
          { name: 'Tahun Akademik' },
          { name: 'Jumlah Provinsi' },
          { name: 'Jumlah Calon Mahasiswa Regular', colspan: 2, rowspan: 1 },
          { name: 'Jumlah Total <br /> Mahasiswa Reguler' },
        ],
        [
          { name: 'Laki-laki', rowspan: 1 },
          { name: 'Perempuan', rowspan: 1 },
        ],
      ])
      const footer = createTableRows([
        [
          { name: 'Jumlah', align: 'center' },
          { name: `${calc.jml_provinsi ?? '0 error'}`, align: 'center' },
          { name: `${calc.jcmr_l ?? '0 error'}`, align: 'center' },
          { name: `${calc.jcmr_p ?? '0 error'}`, align: 'center' },
          { name: `${calc.jtmr ?? '0 error'}`, align: 'center' },
        ],
      ], [4])
      const table = createTable(header, rownum, body, footer)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 3.2.2 Program layanan dan pembinaan minat, bakat, penalaran, kesejahteraan, dan keprofesian mahasiswa',
    name_sheet: '3.2.2',
    endpoint: 'lkps_pldp',
    formatter: (data, calc) => {
      const body = data.map((d, index) => createTableRow([
        d.tahun_akademik,
        d.jenis_program_layanan_dan_pembinaan_minat,
        d.jenis_program_layanan_dan_pembinaan_bakat,
        d.jenis_program_layanan_dan_pembinaan_penalaran,
        d.jenis_program_layanan_dan_pembinaan_kesejahteraan,
        d.jenis_program_layanan_dan_pembinaan_keprofesian,
      ])).join('')
      const rownum = createTableRowNum(6)
      const header = createTableRow([
        'Tahun <br> Akademik',
        'Jenis Program <br> Layanan dan <br> Pembinaan Minat',
        'Jenis Program Layanan <br> dan Pembinaan Bakat',
        'Jenis Program Layanan <br> dan Pembinaan <br> Penalaran',
        'Jenis Program <br> Layanan dan <br> Pembinaan <br> Kesejahteraan',
        'Jenis Program Layanan <br> dan Pembinaan <br> Keprofesian',
      ])
      const footer = createTableRows([
        [
          { name: 'Jumlah', align: 'center' },
          { name: `${calc.jenis_program_layanan_dan_pembinaan_minat ?? '0 error'}`, align: 'center' },
          { name: `${calc.jenis_program_layanan_dan_pembinaan_bakat ?? '0 error'}`, align: 'center' },
          { name: `${calc.jenis_program_layanan_dan_pembinaan_penalaran ?? '0 error'}`, align: 'center' },
          { name: `${calc.jenis_program_layanan_dan_pembinaan_kesejahteraan ?? '0 error'}`, align: 'center' },
          { name: `${calc.jenis_program_layanan_dan_pembinaan_keprofesian ?? '0 error'}`, align: 'center' },
        ],
      ], [5])
      const table = createTable(header, rownum, body, footer)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 4.1.2.2 DTPS yang Bidang Keahliannya Sesuai dengan Bidang PS',
    name_sheet: '4.1.2.2',
    endpoint: 'lkps_dtps_bkps',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.data_master_dosen.full_name,
        d.data_master_dosen.nidn_or_nidk,
        d.data_master_dosen.tanggal_lahir,
        d.data_master_dosen.sertifikat_pendidik,
        d.jabatan_fungsional,
        d.gelar_akademik,
        d.data_master_dosen.combine_jenjang,
        d.data_master_dosen.bidang_keahlian,
      ])).join('')
      const rownum = createTableRowNum(9)
      const header = createTableRow([
        'No.',
        'Nama Lengkap Dosen <br> Tetap*',
        'NIDN/NIDK',
        'Tanggal <br> Lahir',
        'Sertifikat <br> Pendidik <br> (√)',
        'Jabatan <br> Fungsional',
        'Gelar <br> Akademik',
        'Pendidikan S1, <br> S2, S3 dan Asal <br> PT',
        'Bidang <br> Keahlian <br> Setiap Jenjang <br> Pendidikan',
      ])
      const caption = createTableCaption('* Sertakan tautan PDDIKTI atau laman resmi')
      const table = createTable(header, rownum, body, null, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 4.1.2.3 DTPS yang Bidang Keahliannya di Luar Bidang PS',
    name_sheet: '4.1.2.3',
    endpoint: 'lkps_dtps_bkps',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.data_master_dosen.full_name,
        d.data_master_dosen.nidn_or_nidk,
        d.data_master_dosen.tanggal_lahir,
        d.data_master_dosen.sertifikat_pendidik,
        d.jabatan_fungsional,
        d.gelar_akademik,
        d.data_master_dosen.combine_jenjang,
        d.data_master_dosen.bidang_keahlian,
      ])).join('')
      const rownum = createTableRowNum(9)
      const header = createTableRow([
        'No.',
        'Nama Lengkap Dosen <br> Tetap*',
        'NIDN/NIDK',
        'Tanggal <br> Lahir',
        'Sertifikat <br> Pendidik <br> (√)',
        'Jabatan <br> Fungsional',
        'Gelar <br> Akademik',
        'Pendidikan S1, <br> S2, S3 dan Asal <br> PT',
        'Bidang <br> Keahlian <br> Setiap Jenjang <br> Pendidikan',
      ])
      const caption = createTableCaption('* Sertakan tautan PDDIKTI atau laman resmi')
      const table = createTable(header, rownum, body, null, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 4.1.2.4 Rasio DTPS terhadap Mahasiswa Reguler',
    name_sheet: '4.1.2.4',
    endpoint: 'view_lkps_rdtm',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        d.jumlah_dosen_tetap_tabel_4_1_2_2,
        d.jumlah_mahasiswa_reguler_tabel_3_1_2_2,
        d.rasio_dosen_mahasiswa,
      ])).join('')
      const rownum = createTableRowNum(3)
      const header = createTableRow([
        'Jumlah Dosen Tetap (tabel 4.1.2.2) ',
        'Jumlah Mahasiswa Reguler (tabel 3.2.2) ',
        'Rasio dosen:mahasiswa',
      ])
      const table = createTable(header, rownum, body)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 4.1.2.5 Beban Kerja Dosen DTPS',
    name_sheet: '4.1.2.5',
    endpoint: 'lkps_bkddtps',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.data_master_dosen.full_name,
        d.sks_ps_sendiri,
        d.sks_ps_lain_pt_sendiri,
        d.sks_pt_lain,
        d.sks_penelitian,
        d.sks_p2m,
        d.sks_manajemen_pt_sendiri,
        d.sks_manajemen_pt_lain,
        d.jumlah_sks_beban_kerja,
      ])).join('')
      const rownum = createTableRowNum(10)
      const header = createTableRows([
        [
          { name: 'No.' },
          { name: 'Nama Lengkap <br />Dosen Tetap' },
          { name: 'sks* <br />Pembelajaran pada', colspan: 3, rowspan: 1 },
          { name: 'sks*<br />Penelitian' },
          { name: 'sks*<br />P2M' },
          { name: 'sks*<br />Manajamen', colspan: 2, rowspan: 1 },
          { name: 'Jumlah sks*<br />Beban Kerja' },
        ],
        [
          { name: 'PS <br> Sendiri <br> (S1, S2, <br> dan S3)', rowspan: 1 },
          { name: 'PS Lain di PT Sendiri', rowspan: 1 },
          { name: 'PS Lain', rowspan: 1 },
          { name: 'PT Sendiri', rowspan: 1 },
          { name: 'PT Lain', rowspan: 1 },
        ],
      ])
      const footer = createTableRows([
        [{
          name: 'Jumlah', colspan: 2, rowspan: 1, align: 'left',
        }],
        [{
          name: 'Rata-rata', colspan: 2, rowspan: 1, align: 'left',
        }],
      ])
      const caption = createTableCaption('*Rata-rata sks di semester gasal dan genap')
      const table = createTable(header, rownum, body, footer, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 4.1.2.6 Kegiatan Mengajar Dosen Tetap',
    name_sheet: ['4.1.2.6-1', '4.1.2.6-2'],
    endpoint: 'lkps_kmdtsg',
    formatter: results => {
      const rownum = createTableRowNum(8)
      const header = createTableRow([
        'No.',
        'Nama Lengkap <br> Dosen Tetap',
        'Jumlah <br> Kelas',
        'Jumlah <br> sks',
        'Kode Mata <br> Kuliah',
        'Nama Mata Kuliah',
        'Jumlah <br> Pertemuan <br> yang <br> Direncanakan',
        'Jumlah <br> Pertemuan <br> yang <br> Dilaksanakan',
      ])
      // FIRST DATA
      const body = results.map((data, index) => {
        let dataTable = ''
        const dataHeaderName = {
          0: 'Semester Gasal',
          1: 'Semester Genap',
        }[index]
        dataTable += createTableRows([
          [{
            name: dataHeaderName, colspan: 8, rowspan: 1, align: 'left',
          }],
        ])
        dataTable += data.map((d, index) => createTableRow([
          index + 1,
          d.data_master_dosen.full_name,
          d.jumlah_kelas,
          d.jumlah_sks,
          d.matkul.code_mk,
          d.matkul.name,
          d.jumlah_pertemuan_direncanakan,
          d.jumlah_pertemuan_dilaksanakan,
        ])).join('')
        dataTable += createTableRows([
          [{ name: 'Jumlah', colspan: 6, rowspan: 1 }],
          [{ name: 'Rata-Rata', colspan: 6, rowspan: 1 }],
        ])
        return dataTable
      }).join('')
      const table = createTable(header, rownum, body)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 4.1.2.7 Jumlah Bimbingan Tugas Akhir atau Skripsi, Tesis, dan Disertasi',
    name_sheet: '4.1.2.7',
    endpoint: 'lkps_jbtastd',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.data_master_dosen.full_name,
        d.jml_mhs_bimbingan_ts_1,
        d.jml_mhs_bimbingan_ts_2,
        d.jml_mhs_bimbingan_ts,
        d.jumlah,
        d.rata_rata_tahun,
      ])).join('')
      const rownum = createTableRowNum(7)
      const header = createTableRows([
        [
          { name: 'No.' },
          { name: 'Nama Lengkap Dosen Tetap' },
          { name: 'Jumlah Mahasiswa Bimbingan', colspan: 5, rowspan: 1 },
        ],
        [
          { name: 'TS-2', rowspan: 1 },
          { name: 'TS-1', rowspan: 1 },
          { name: 'TS', rowspan: 1 },
          { name: 'Jumlah', rowspan: 1 },
          { name: 'Rata-Rata/Tahun', rowspan: 1 },
        ],
      ])
      const footer = createTableRows([
        [{
          name: 'Jumlah', colspan: 2, rowspan: 1, align: 'left',
        }],
        [{
          name: 'Rata-rata', colspan: 2, rowspan: 1, align: 'left',
        }],
      ], [5, 5])
      const table = createTable(header, rownum, body, footer)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 4.1.2.8 Prestasi DTPS',
    name_sheet: '4.1.2.8',
    endpoint: 'lkps_prd',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.data_master_dosen.full_name,
        d.prestasi_yang_dicapai,
        d.tahun_pencapaian,
        d.internasional ? '✓' : '✕',
        d.nasional ? '✓' : '✕',
        d.lokal ? '✓' : '✕',
        d.bukti_prestasi,
      ])).join('')
      const rownum = createTableRowNum(7)
      const header = createTableRows([
        [
          { name: 'No.' },
          { name: 'Nama Lengkap Dosen' },
          { name: 'Prestasi yang Dicapai' },
          { name: 'Tahun <br />Pencapaian' },
          { name: 'Tingkat*', colspan: 3, rowspan: 1 },
          { name: 'Bukti <br />Prestasi**' },
        ],
        [
          { name: 'Internasional', rowspan: 1 },
          { name: 'Nasional', rowspan: 1 },
          { name: 'Lokal', rowspan: 1 },
        ],
      ])
      const footer = createTableRows([
        [{
          name: 'Jumlah', colspan: 3, rowspan: 1, align: 'left',
        }],
      ], [5])
      const caption = createTableCaption('* Beri tanda centhang (√) pada kolom yang sesuai <br> ** Contoh: Sertifikat')
      const table = createTable(header, rownum, body, footer, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 4.1.2.9 Pengembangan Kompetensi DTPS',
    name_sheet: ['4.1.2.9-1', '4.1.2.9-2', '4.1.2.9-3'],
    endpoint: 'lkps_pkdtps',
    formatter: results => {
      const rownum = createTableRowNum(7)
      const header = createTableRow([
        'No.',
        'Nama Dosen',
        'Bidang Keahlian',
        'Nama Kegiatan*',
        'Tempat Kegiatan',
        'Waktu <br> Kegiatan',
        'Manfaat <br> Kegiatan',
      ])
      // FIRST DATA
      const body = results.map((data, index) => {
        let dataTable = ''
        const dataHeaderName = {
          0: 'TS',
          1: 'TS-1',
          2: 'TS-2',
        }[index]
        dataTable += createTableRows([
          [{
            name: dataHeaderName, colspan: 7, rowspan: 1, align: 'left',
          }],
        ])
        dataTable += data.map((d, index) => createTableRow([
          index + 1,
          d.data_master_dosen.full_name,
          d.data_master_dosen.bidang_keahlian,
          d.nama_kegiatan,
          d.tempat_kegiatan,
          d.waktu_kegiatan,
          d.manfaat_kegiatan,
        ])).join('')
        dataTable += createTableRows([
          [{
            name: `Jumlah kegiatan di ${dataHeaderName}: …`, colspan: 7, rowspan: 1, align: 'left',
          }],
        ])
        return dataTable
      }).join('')
      const footer = createTableRows([
        [{
          name: '&nbsp;', colspan: 7, rowspan: 1, align: 'left',
        }],
        [{
          name: 'Jumlah keseluruhan kegiatan : …', colspan: 7, rowspan: 1, align: 'left',
        }],
        [{
          name: 'Rata-rata jumlah kegiatan per tahun : …', colspan: 7, rowspan: 1, align: 'left',
        }],
      ])
      const caption = createTableCaption('* Sertakan bukti kegiatan')
      const table = createTable(header, rownum, body, footer, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 4.2.2.2  Profil Tendik',
    name_sheet: '4.2.2.2',
    endpoint: 'lkps_tendik',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.data_master_dosen.full_name,
        d.status_kepegawaian,
        d.data_master_dosen.bidang_keahlian,
        d.data_master_dosen.combine_jenjang,
        d.unit_kerja,
      ])).join('')
      const rownum = createTableRowNum(6)
      const header = createTableRow([
        'No.',
        'Nama Lengkap Tenaga <br> Kependidikan',
        'Status Kepegawaian <br> (PNS, Tetap, Non-PNS, <br> Kontrak, dll)',
        'Bidang Keahlian (administrator, pustakawan, <br> laboran, dll)*',
        'Pendidikan <br> (S1, S2, <br> S3)',
        'Unit Kerja (PS, <br> UPPS, PT)',
      ])
      const caption = createTableCaption('*Sertakan fotokopi ijasah atau sertifikat kompetensi')
      const table = createTable(header, rownum, body, null, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 4.2.2.3  Pengembangan Kompetensi dan Karier Tendik',
    name_sheet: '4.2.2.3',
    endpoint: 'lkps_pkdkt',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.data_master_dosen.full_name,
        d.nama_kegiatan_pengembangan_kompetensi,
        d.lama_kegiatan_tahun_bulan_hari,
        d.waktu_mulai_kegiatan,
        d.waktu_berakhir_kegiatan,
        d.tempat_kegiatan,
      ])).join('')
      const rownum = createTableRowNum(7)
      const header = createTableRow([
        'No.',
        'Nama Tenaga Kependidikan',
        'Nama Kegiatan <br> Pengembangan Kompetensi*',
        'Lama Kegiatan <br> (tahun, bulan, hari)',
        'Waktu Mulai <br> Kegiatan',
        'Waktu <br> Berakhir <br> Kegiatan',
        'Tempat Kegiatan',
      ])
      const caption = createTableCaption('*Sertakan bukti kegiatan')
      const table = createTable(header, rownum, body, null, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 4.3.2 Jenis instrumen yang digunakan untuk mengukur kepuasan dosen dan tenaga kependidikan terhadap manajemen SDM.',
    name_sheet: '4.3.2',
    endpoint: 'lkps_jiyd',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.jenis_instrumen,
        d.pengembang_dan_validator,
        d.pelaksanaan_uji_coba_instrumen,
        d.finalisasi_instumen,
      ])).join('')
      const rownum = createTableRowNum(5)
      const header = createTableRow([
        'No.',
        'Jenis Instrumen',
        'Pengembang dan Validator',
        'Pelaksanaan Uji Coba Instrumen',
        'Finalisasi Instumen',
      ])
      const table = createTable(header, rownum, body)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 4.3.3 Pelaksanaan pengukuran kepuasan dosen terhadap manajemen SDM',
    name_sheet: '4.3.3',
    endpoint: 'lkps_ppkedos',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.waktu_pelaksanaan,
        d.sasaran,
        d.hasil,
        d.tindak_lanjut,
      ])).join('')
      const rownum = createTableRowNum(5)
      const header = createTableRows([
        [
          { name: 'Sumber Dana' },
          { name: 'Jenis Dana' },
          { name: 'Jumlah Dana (dalam jutaan)', colspan: 4, rowspan: 1 },
        ],
        [
          { name: 'TS-2', rowspan: 1 },
          { name: 'TS-1', rowspan: 1 },
          { name: 'TS', rowspan: 1 },
          { name: 'Rata-rata', rowspan: 1 },
        ],
      ])
      const footer = createTableRows([
        [{
          name: 'Jumlah', colspan: 2, rowspan: 1, align: 'left',
        }],
      ], [4])
      const table = createTable(header, rownum, body, footer)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 4.3.4 Pelaksanaan pengukuran kepuasan tenaga kependidikan terhadap manajemen SDM',
    name_sheet: '4.3.4',
    endpoint: 'lkps_ppkedos',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.waktu_pelaksanaan,
        d.sasaran,
        d.hasil,
        d.tindak_lanjut,
      ])).join('')
      const rownum = createTableRowNum(5)
      const header = createTableRow([
        'No.',
        'Waktu Pelaksanaan',
        'Sasaran',
        'Hasil',
        'Tindak Lanjut',
      ])
      const table = createTable(header, rownum, body)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 5.1.2.1 Pemerolehan Dana',
    name_sheet: '5.1.2.1',
    endpoint: 'lkps_pemdana',
    formatter: (data, calc) => {
      const sources = [...new Set(data.map(d => d.sumber_dana))]
      const sources_data = sources
        .map(source => data.filter(d => d.sumber_dana === source))
        .flatMap(source_data => {
          const arr = source_data.map((data, index, arr) => {
            const cols = `
              <td><p style="text-align:center;">${data.jenis_dana}</p></td>
              <td><p style="text-align:center;">${data.ts_2}</p></td>
              <td><p style="text-align:center;">${data.ts_1}</p></td>
              <td><p style="text-align:center;">${data.ts}</p></td>
              <td><p style="text-align:center;">${data.rata_rata}</p></td>
            `
            const final_cols = index === 0 ? `<td rowspan="${arr.length}"><p style="text-align:center;">${data.sumber_dana}</p></td>${cols}` : cols
            const row = `<tr>${final_cols}</tr>`
            return row
          })
          return arr
        })
      const body = sources_data.join('')
      const rownum = createTableRowNum(6)
      const header = createTableRows([
        [
          { name: 'Sumber Dana' },
          { name: 'Jenis Dana' },
          { name: 'Jumlah Dana (dalam jutaan)', colspan: 4, rowspan: 1 },
        ],
        [
          { name: 'TS-2', rowspan: 1 },
          { name: 'TS-1', rowspan: 1 },
          { name: 'TS', rowspan: 1 },
          { name: 'Rata-rata', rowspan: 1 },
        ],
      ])
      const footer = createTableRows([
        [
          {
            name: 'Total', colspan: 2, rowspan: 1, align: 'right',
          },
          { name: `${calc.ts_2 ?? '0 error'}`, align: 'center' },
          { name: `${calc.ts_1 ?? '0 error'}`, align: 'center' },
          { name: `${calc.ts ?? '0 error'}`, align: 'center' },
          { name: `${calc.rata_rata ?? '0 error'}`, align: 'center' },
        ],
      ], [4])
      const table = createTable(header, rownum, body, footer)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 5.1.2.2 Penggunaan Dana',
    name_sheet: '5.1.2.2',
    endpoint: 'lkps_pengdana',
    formatter: (data, calc) => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.jenis_penggunaan,
        d.ts_2,
        d.ts_1,
        d.ts,
        d.rata_rata,
      ])).join('')
      const rownum = createTableRowNum(6)
      const header = createTableRows([
        [
          { name: 'No.' },
          { name: 'Jenis Penggunaan' },
          { name: 'Jumlah Dana (dalam jutaan)', colspan: 4, rowspan: 1 },
        ],
        [
          { name: 'TS-2', rowspan: 1 },
          { name: 'TS-1', rowspan: 1 },
          { name: 'TS', rowspan: 1 },
          { name: 'Rata-rata', rowspan: 1 },
        ],
      ])
      const footer = createTableRows([
        [
          {
            name: 'Total', colspan: 2, rowspan: 1, align: 'center',
          },
          { name: `${calc.ts_2 ?? '0 error'}`, align: 'center' },
          { name: `${calc.ts_1 ?? '0 error'}`, align: 'center' },
          { name: `${calc.ts ?? '0 error'}`, align: 'center' },
          { name: `${calc.rata_rata ?? '0 error'}`, align: 'center' },
        ],
      ], [4])
      const table = createTable(header, rownum, body, footer)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 5.1.2.3 Dana Penelitian',
    name_sheet: '5.1.2.3',
    endpoint: 'lkps_danapel',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.judul_penelitian,
        d.data_master_dosen.full_name,
        d.sumber_dan_jenis_dana,
        d.ts_2,
        d.ts_1,
        d.ts,
        d.rata_rata,
      ])).join('')
      const rownum = createTableRowNum(8)
      const header = createTableRows([
        [
          { name: 'Tahun' },
          { name: 'Judul Penelitian' },
          { name: 'Ketua Tim' },
          { name: 'Sumber dan Jenis <br> Dana' },
          { name: 'Jumlah Dana (dalam jutaan)', colspan: 4, rowspan: 1 },
        ],
        [
          { name: 'TS-2', rowspan: 1 },
          { name: 'TS-1', rowspan: 1 },
          { name: 'TS', rowspan: 1 },
          { name: 'Rata-rata', rowspan: 1 },
        ],
      ])
      // const footer = createTableRows([
      //   [{ name: 'Jumlah', colspan: 4, rowspan: 1 }],
      //   [{ name: 'Rata-rata', colspan: 4, rowspan: 1 }],
      // ], [4])
      const table = createTable(header, rownum, body, null)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 5.1.2.4 Dana PkM',
    name_sheet: '5.1.2.4',
    endpoint: 'lkps_danapel',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.judul_penelitian,
        d.data_master_dosen.full_name,
        d.sumber_dan_jenis_dana,
        d.ts_2,
        d.ts_1,
        d.ts,
        d.rata_rata,
      ])).join('')
      const rownum = createTableRowNum(8)
      const header = createTableRows([
        [
          { name: 'Tahun' },
          { name: 'Judul Penelitian' },
          { name: 'Ketua Tim' },
          { name: 'Sumber dan Jenis <br> Dana' },
          { name: 'Jumlah Dana (dalam jutaan)', colspan: 4, rowspan: 1 },
        ],
        [
          { name: 'TS-2', rowspan: 1 },
          { name: 'TS-1', rowspan: 1 },
          { name: 'TS', rowspan: 1 },
          { name: 'Rata-rata', rowspan: 1 },
        ],
      ])
      // const footer = createTableRows([
      //   [{ name: 'Jumlah', colspan: 4, rowspan: 1 }],
      //   [{ name: 'Rata-rata', colspan: 4, rowspan: 1 }],
      // ], [4])
      const table = createTable(header, rownum, body, null)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 5.2.2.1 Data Prasarana Pendidikan',
    name_sheet: '5.2.2.1',
    endpoint: 'lkps_dpp',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.jenis_prasarana,
        d.jumlah_unit,
        d.luas_m_2,
        d.sd ? '✓' : '✕',
        d.sw ? '✓' : '✕',
        d.terawat ? '✓' : '✕',
        d.tidak_terawat ? '✓' : '✕',
        d.penggunaan_jam_minggu,
      ])).join('')
      const rownum = createTableRowNum(9)
      const header = createTableRows([
        [
          { name: 'Jenis Prasarana' },
          { name: 'Judul <br> Unit' },
          { name: 'Luas (m<sup>2</sup>)' },
          { name: 'Kepemilikan*', colspan: 2, rowspan: 1 },
          { name: 'Kondisi*', colspan: 2, rowspan: 1 },
          { name: 'Penggunaan <br> (Jam/minggu)' },
        ],
        [
          { name: 'SD', rowspan: 1 },
          { name: 'SW', rowspan: 1 },
          { name: 'Terawat', rowspan: 1 },
          { name: 'Tidak Terawat', rowspan: 1 },
        ],
      ])
      const caption = createTableCaption('* Beri tanda centang (√) pada kolom yang sesuai: SD = Milik Sendiri; SW = Sewa/Kontrak/Kerjasama')
      const table = createTable(header, rownum, body, null, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 5.2.2.2 Data Prasarana Pendidikan',
    name_sheet: '5.2.2.2',
    endpoint: 'lkps_dsp',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.jenis_sarana,
        d.jumlah_unit,
        d.kualitas ? '✓' : '✕',
        d.terawat ? '✓' : '✕',
        d.tidak_terawat ? '✓' : '✕',
        d.unit_pengelola_ps_upps_pt,
      ])).join('')
      const rownum = createTableRowNum(7)
      const header = createTableRows([
        [
          { name: 'Jenis Sarana' },
          { name: 'Judul Unit' },
          { name: 'Kualitas*' },
          { name: 'Kondisi**', colspan: 2, rowspan: 1 },
          { name: 'Unit Pengelola <br> (PS, UPPS, PT)' },
        ],
        [
          { name: 'Terawat', rowspan: 1 },
          { name: 'Tidak Terawat', rowspan: 1 },
        ],
      ])
      const caption = createTableCaption('* Diisi: sangat baik, baik, kurang baik, atau tidak baik <br> ** Diisi dengan tanda centang (√) pada kolom yang sesuai')
      const table = createTable(header, rownum, body, null, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 6.1.2.2 Mata Kuliah, CPL, dan Perangkat Pembelajaran',
    name_sheet: '6.1.2.2',
    endpoint: 'lkps_mkcp',
    formatter: data => {
      const body = [...new Set(data.map(d => d.semester))]
        .map(semester => {
          const dataTableData = data.filter(d => d.semester === semester && d.matkul_pilihan !== true)
          let dataTable = ''
          dataTable += createTableRows([
            [{
              name: `Semester ${semester}`, colspan: 12, rowspan: 1, align: 'left',
            }],
          ])
          dataTable += dataTableData
            .map((d, index) => createTableRow([
              index + 1,
              d.matkul.code_mk,
              d.matkul.name,
              d.teori ? '✓' : '✕',
              d.praktikum ? '✓' : '✕',
              d.praktik ? '✓' : '✕',
              d.jumlah_sks,
              d.pt ? '✓' : '✕',
              d.upps ? '✓' : '✕',
              d.ps ? '✓' : '✕',
              d.kesesuaian_dengan_cpl ? '✓' : '✕',
              d.perangkat_pembelajaran ? '✓' : '✕',
            ]))
            .join('')
          return dataTable
        })
        .join('')
      const rownum = createTableRowNum(12)
      const header = createTableRows([
        [
          { name: 'No.' },
          { name: 'Kode MK' },
          { name: 'Jenis Mata Kuliah*', colspan: 3, rowspan: 1 },
          { name: 'Jumlah <br> sks' },
          { name: 'Unit Penyelenggara*', colspan: 3, rowspan: 1 },
          { name: 'Kesesuaian <br> dengan CPL*' },
          { name: 'Perangkat <br> Pembelajaran*' },
        ],
        [
          { name: 'Teori', rowspan: 1 },
          { name: 'Praktikum', rowspan: 1 },
          { name: 'Praktik', rowspan: 1 },
          { name: 'PT', rowspan: 1 },
          { name: 'UPPS', rowspan: 1 },
          { name: 'PS', rowspan: 1 },
        ],
      ])
      const caption = createTableCaption('*Beri tanda √ pada kolom yang sesuai dengan menambahkan prosentase ketercapaian')
      const table = createTable(header, rownum, body, null, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 6.2.2.2 Integrasi Hasil Penelitian dan PkM dalam Proses Pembelajaran',
    name_sheet: '6.2.2.2',
    endpoint: 'lkps_ihp',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.judul,
        d.data_master_dosen.full_name,
        d.matkul.name,
        d.bntk_integrasi,
      ])).join('')
      const rownum = createTableRowNum(5)
      const header = createTableRow([
        'Nomor',
        'Judul Penelitian atau <br> PKM',
        'Nama Dosen',
        'Nama Mata Kuliah',
        'Bentuk Integrasi*',
      ])
      const caption = createTableCaption('*Contoh: sebagai referensi atau materi pembelajaran')
      const table = createTable(header, rownum, body, null, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 6.5.2.1 Proses Pembimbingan Akademik',
    name_sheet: '6.5.2.1',
    endpoint: null,
    formatter: data => {
      const aspects = [
        'Topik yang dibahas dalam pembimbingan',
        'Tujuan dilaksanakannya pembimbingan',
        'Pelaksanaan pembimbingan (tempat, waktu, moda, cara, dll)',
        'Masalah yang muncul dalam pembimbingan dan upaya mengatasinya',
        'Manfaat yang diperoleh mahasiswa dari pembimbingan',
      ]
      const body = aspects.map((d, index) => createTableRow([
        index + 1,
        d,
        '',
      ])).join('')
      const rownum = createTableRowNum(3)
      const header = createTableRow([
        'No',
        'Aspek Pembimbingan Akademik',
        'Deskripsi',
      ])
      const table = createTable(header, rownum, body)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 6.5.2.2 Jumlah Mahasiswa Bimbingan dan Frekuensi Pertemuan',
    name_sheet: '6.5.2.2',
    endpoint: 'lkps_jmbfp',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.data_master_dosen.full_name,
        d.jumlah_mahasiswa_bimbingan,
        d.rata_rata_banyaknya_pertemuan_mahasiswa_semester,
      ])).join('')
      const rownum = createTableRowNum(4)
      const header = createTableRow([
        'No.',
        'Nama Dosen Pembimbing <br> Akademik',
        'Jumlah Mahasiswa <br> Bimbingan',
        'Rata-rata banyaknya Pertemuan/<br> Mahasiswa/Semester*',
      ])
      const footer = createTableRows([
        [{ name: 'Rata-rata banyaknya pertemuan per mahasiswa per <br> semester', colspan: 3, rowspan: 1 }],
      ])
      const caption = createTableCaption('*Sertakan bukti yang relevan')
      const table = createTable(header, rownum, body, footer, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 6.5.2.3 Proses Pembimbingan Magang Kependidikan',
    name_sheet: '6.5.2.3',
    endpoint: null,
    formatter: data => {
      const aspects = [
        'Topik yang dibahas dalam pembimbingan',
        'Tujuan dilaksanakannya pembimbingan',
        'Pelaksanaan pembimbingan (tempat, waktu, moda, cara, dll)',
        'Masalah yang muncul dalam pembimbingan dan upaya mengatasinya',
        'Manfaat yang diperoleh mahasiswa dari pembimbingan',
      ]
      const body = aspects.map((d, index) => createTableRow([
        index + 1,
        d,
        '',
      ])).join('')
      const rownum = createTableRowNum(3)
      const header = createTableRow([
        'No',
        'Aspek Pembimbingan Akademik',
        'Deskripsi',
      ])
      const table = createTable(header, rownum, body)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 6.5.2.4 Jumlah Mahasiswa Bimbingan Magang Kependidikan dan Frekuensi Pertemuan',
    name_sheet: '6.5.2.4',
    endpoint: 'lkps_jmbfp',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.data_master_dosen.full_name,
        d.jumlah_mahasiswa_bimbingan,
        d.rata_rata_banyaknya_pertemuan_mahasiswa_semester,
      ])).join('')
      const rownum = createTableRowNum(4)
      const header = createTableRow([
        'No.',
        'Nama Dosen Pembimbing <br> Magang Kependidikan',
        'Jumlah <br> Mahasiswa <br> Bimbingan',
        'Rata-rata banyaknya Pertemuan/<br> Mahasiswa/Semester*',
      ])
      const footer = createTableRows([
        [{ name: 'Rata-rata banyaknya pertemuan per mahasiswa per <br> semester', colspan: 3, rowspan: 1 }],
      ])
      const caption = createTableCaption('*Sertakan bukti yang relevan')
      const table = createTable(header, rownum, body, footer, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 6.5.2.5 Proses Pembimbingan Tugas Akhir atau Skripsi',
    name_sheet: '6.5.2.5',
    endpoint: null,
    formatter: data => {
      const aspects = [
        'Topik yang dibahas dalam pembimbingan',
        'Tujuan dilaksanakannya pembimbingan',
        'Pelaksanaan pembimbingan (tempat, waktu, moda, cara, dll)',
        'Masalah yang muncul dalam pembimbingan dan upaya mengatasinya',
        'Manfaat yang diperoleh mahasiswa dari pembimbingan',
      ]
      const body = aspects.map((d, index) => createTableRow([
        index + 1,
        d,
        '',
      ])).join('')
      const rownum = createTableRowNum(3)
      const header = createTableRow([
        'No',
        'Aspek Pembimbingan Akademik',
        'Deskripsi',
      ])
      const table = createTable(header, rownum, body)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 6.5.2.6 Jumlah Mahasiswa Bimbingan Tugas Akhir atau Skripsi dan Frekuensi Pertemuan',
    name_sheet: '6.5.2.6',
    endpoint: 'lkps_jmbta',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.data_master_dosen.full_name,
        d.s_ts_2,
        d.s_ts_1,
        d.s_ts,
        d.s_rata,
        d.l_ts_2,
        d.l_ts_1,
        d.l_ts,
        d.l_rata,
        d.rata_rata_jumlah_mahasiswa_bimbingan,
        d.rata_rata_banyaknya_pertemuan,
      ])).join('')
      const rownum = createTableRowNum(10)
      const header = createTableRows([
        [
          { name: 'No', rowspan: 3 },
          { name: 'Nama Dosen Pembimbing Tugas <br> Akhir', rowspan: 3 },
          { name: 'Banyaknya Mahasiswa Bimbingan', colspan: 8, rowspan: 1 },
          { name: 'Rata-Rata <br> Jumlah <br> Mahasiswa <br> Bimbingan', rowspan: 3 },
          { name: 'Rata-Rata <br> Banyaknya <br> Pertemuan <br> *', rowspan: 3 },
        ],
        [
          { name: 'Di PS Sendiri', colspan: 4, rowspan: 1 },
          { name: 'Di PS Lain', colspan: 4, rowspan: 1 },
        ],
        [
          { name: 'TS-2', rowspan: 1 },
          { name: 'TS-1', rowspan: 1 },
          { name: 'TS', rowspan: 1 },
          { name: 'Rata-rata', rowspan: 1 },
          { name: 'TS-2', rowspan: 1 },
          { name: 'TS-1', rowspan: 1 },
          { name: 'TS', rowspan: 1 },
          { name: 'Rata-rata', rowspan: 1 },
        ],
      ])
      const footer = createTableRows([
        [{ name: 'Rata-rata', colspan: 10, rowspan: 1 }],
      ], [4])
      const caption = createTableCaption('*Sertakan bukti yang relevan')
      const table = createTable(header, rownum, body, footer, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 6.6.2.1 Kegiatan Akademik di Luar Perkuliahan',
    name_sheet: '6.6.2.1',
    endpoint: 'lkps_kalp',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.nama_kegiatan,
        d.data_master_dosen.full_name,
        d.frekuensi_kegiatan,
        d.hasil_kegiatan,
        d.bukti_kegiatan,
      ])).join('')
      const rownum = createTableRowNum(6)
      const header = createTableRow([
        'No',
        'Nama Kegiatan',
        'Nama Dosen Pembimbing',
        'Frekuensi <br> Kegiatan*',
        'Hasil Kegiatan',
        'Bukti Kegiatan**',
      ])
      const caption = createTableCaption('*Contoh: Sekali dalam satu bulan atau tiga bulan atau satu semester <br> **Contoh: Laporan pelaksanaan kegiatan')
      const table = createTable(header, rownum, body, null, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 6.6.2.2 Dosen Tamu dan Tenaga Ahli',
    name_sheet: '6.6.2.2',
    endpoint: 'lkps_dtta',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.data_master_dosen.full_name,
        d.nama_lembaga,
        d.kepakaran,
        d.mata_kuliah,
        d.waktu_kegiatan,
        d.bukti_kegiatan,
      ])).join('')
      const rownum = createTableRowNum(7)
      const header = createTableRow([
        'No',
        'Nama Lengkap Dosen Tamu <br> dan Tenaga Ahli',
        'Nama Lembaga',
        'Kepakaran',
        'Mata Kuliah',
        'Waktu Kegiatan',
        'Bukti Kegiatan*',
      ])
      const caption = createTableCaption('*Contoh: Laporan pelaksanaan kegiatan')
      const table = createTable(header, rownum, body, null, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 6.7.2 Kepuasan Mahasiswa',
    name_sheet: '6.7.2',
    endpoint: 'lkps_kepmhs',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.aspek_pengukuran_kepuasan,
        d.kinerja_mengajar_dtps ? '✓' : '✕',
        d.layanan_administrasi_akademik_oleh_ps ? '✓' : '✕',
        d.prasarana_dan_sarana_pembelajaran_di_ps ? '✓' : '✕',
        d.tindak_lanjut,
      ])).join('')
      const rownum = createTableRowNum(6)
      const header = createTableRows([
        [
          { name: 'No.' },
          { name: 'Aspek Pengukuran Kepuasan' },
          { name: 'Objek Kepuasan Mahasiswa', colspan: 3, rowspan: 1 },
          { name: 'Tindak Lanjut' },
        ],
        [
          { name: 'Kinerja Mengajar <br> DTPS' },
          { name: 'Layanan Administrasi <br> Akademik oleh PS' },
          { name: 'Prasarana dan Sarana <br> Pembelajaran di PS' },
        ],
      ])
      const table = createTable(header, rownum, body)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 7.2.2 Aktivitas, Relevansi, dan Pelibatan Mahasiswa dalam Penelitian',
    name_sheet: '7.2.2',
    endpoint: 'lkps_arpmd',
    formatter: data => {
      const body = [...new Set(data.map(d => d.tahun_akademik))]
        .map(tahun_akademik => {
          const dataTableData = data.filter(d => d.tahun_akademik === tahun_akademik && d.matkul_pilihan !== true)
          let dataTable = ''
          dataTable += createTableRows([
            [{
              name: `TS (${tahun_akademik})`, colspan: 6, rowspan: 1, align: 'left',
            }],
          ])
          dataTable += dataTableData
            .map((d, index) => createTableRow([
              index + 1,
              d.judul_penelitian,
              d.data_master_dosen.full_name,
              d.kepakaran_ketua_tim,
              d.nama_dan_identitas_dosen_anggota_penelitian.join(', '),
              d.nama_dan_identitas_mahasiswa_yang_dilibatkan.join(', '),
            ]))
            .join('')
          dataTable += createTableRows([
            [{
              name: `Jumlah judul penelitian di TS ${tahun_akademik} : ${dataTableData.length}`, colspan: 6, rowspan: 1, align: 'left',
            }],
            [{ name: '&nbsp;', colspan: 6, rowspan: 1 }],
          ])
          return dataTable
        })
        .join('')
      const rownum = createTableRowNum(6)
      const header = createTableRow([
        'No.',
        'Nama Ketua Tim',
        'Kepakaran Ketua Tim',
        'Nama dan Identitas <br> Dosen Anggota <br> Penelitian',
        'Nama dan Identitas <br> DMahasiswa yang <br> dilibatkan',
      ])
      const footer = createTableRows([
        [{
          name: 'Jumlah total penelitian di PS dalam tiga tahun terakhir: ', colspan: 6, rowspan: 1, align: 'left',
        }],
        [{
          name: 'Jumlah rata-rata judul penelitian per tahun/dosen: ', colspan: 6, rowspan: 1, align: 'left',
        }],
      ])
      const caption = createTableCaption('*Diisi dengan kepakaran (expertise) atau research interest ketua tim peneliti.')
      const table = createTable(header, rownum, body, footer, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 8.2.2 Aktivitas, Relevansi, dan Pelibatan Mahasiswa dalam PkM',
    name_sheet: '8.2.2',
    endpoint: 'lkps_arpmd',
    formatter: data => {
      const body = [...new Set(data.map(d => d.tahun_akademik))]
        .map(tahun_akademik => {
          const dataTableData = data.filter(d => d.tahun_akademik === tahun_akademik && d.matkul_pilihan !== true)
          let dataTable = ''
          dataTable += createTableRows([
            [{
              name: `TS (${tahun_akademik})`, colspan: 6, rowspan: 1, align: 'left',
            }],
          ])
          dataTable += dataTableData
            .map((d, index) => createTableRow([
              index + 1,
              d.judul_penelitian,
              d.data_master_dosen.full_name,
              d.kepakaran_ketua_tim,
              d.nama_dan_identitas_dosen_anggota_penelitian.join(', '),
              d.nama_dan_identitas_mahasiswa_yang_dilibatkan.join(', '),
            ]))
            .join('')
          dataTable += createTableRows([
            [{
              name: `Jumlah judul PkM di TS ${tahun_akademik} : ${dataTableData.length}`, colspan: 6, rowspan: 1, align: 'left',
            }],
            [{ name: '&nbsp;', colspan: 6, rowspan: 1 }],
          ])
          return dataTable
        })
        .join('')
      const rownum = createTableRowNum(6)
      const header = createTableRow([
        'No.',
        'Nama Ketua Tim',
        'Kepakaran Ketua Tim',
        'Nama dan Identitas <br> Dosen Anggota <br> PkM',
        'Nama dan Identitas <br> DMahasiswa yang <br> dilibatkan',
      ])
      const footer = createTableRows([
        [{
          name: 'Jumlah total penelitian di PS dalam tiga tahun terakhir: ', colspan: 6, rowspan: 1, align: 'left',
        }],
        [{
          name: 'Jumlah rata-rata judul PkM per tahun/dosen: ', colspan: 6, rowspan: 1, align: 'left',
        }],
      ])
      const caption = createTableCaption('*Diisi dengan kepakaran (expertise) atau research interest ketua tim peneliti.')
      const table = createTable(header, rownum, body, footer, caption)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 9.1.2.1 IPK Lulusan',
    name_sheet: '9.1.2.1',
    endpoint: 'lkps_ipk',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        d.tahun_lulus,
        d.jml_lulus,
        d.ipk_min,
        d.ipk_rata,
        d.ipk_maks,
      ])).join('')
      const rownum = createTableRowNum(5)
      const header = createTableRows([
        [
          { name: 'Tahun Lulus' },
          { name: 'Jumlah Lulusan' },
          { name: 'Indeks Prestasi Kumulatif (IPK)', colspan: 3, rowspan: 1 },
        ],
        [
          { name: 'Minimum', rowspan: 1 },
          { name: 'Rata-Rata', rowspan: 1 },
          { name: 'Maksimum', rowspan: 1 },
        ],
      ])
      const table = createTable(header, rownum, body)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 9.1.2.2 Prestasi Mahasiswa',
    name_sheet: '9.1.2.2',
    endpoint: 'lkps_prestasiakm',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.data_master_dosen.full_name,
        d.prestasi_yang_dicapai,
        d.waktu_pencapaian,
        d.internasional ? '✓' : '✕',
        d.nasional ? '✓' : '✕',
        d.lokal ? '✓' : '✕',
      ])).join('')
      const rownum = createTableRowNum(7)
      const header = createTableRows([
        [
          { name: 'No.' },
          { name: 'Nama Mahasiswa' },
          { name: 'Prestasi yang Dicapai' },
          { name: 'Waktu Pencapaian' },
          { name: 'Tingkat', colspan: 3, rowspan: 1 },
        ],
        [
          { name: 'Internasional', rowspan: 1 },
          { name: 'Nasional', rowspan: 1 },
          { name: 'Lokal', rowspan: 1 },
        ],
      ])
      const footer = createTableRows([
        [{ name: 'Jumlah', colspan: 4, rowspan: 1 }],
      ])
      const table = createTable(header, rownum, body, footer)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 9.1.2.3 Masa Studi, Kelulusan Tepat Waktu, dan Keberhasilan Studi',
    name_sheet: '9.1.2.3',
    endpoint: 'lkps_mskt',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        d.tahun_masuk,
        d.jumlah_mahasiswa_diterima,
        d.akhir_ts_6,
        d.akhir_ts_5,
        d.akhir_ts_4,
        d.akhir_ts_3,
        d.akhir_ts_2,
        d.akhir_ts_1,
        d.akhir_ts,
        d.jumlah_lulusan_sd_akhir_ts,
        d.rata_rata_masa_studi,
      ])).join('')
      const rownum = createTableRowNum(11)
      const header = createTableRows([
        [
          { name: 'Tahun <br> Masuk' },
          { name: 'Jumlah <br> Mahasiswa <br> Diterima' },
          { name: 'Jumlah Mahasiswa yang Lulus pada ...', colspan: 7, rowspan: 1 },
          { name: 'Jumlah <br> Lulusan sd <br> Akhir TS' },
          { name: 'Rata-rata <br> Masa Studi' },
        ],
        [
          { name: 'Akhir TS-6', rowspan: 1 },
          { name: 'Akhir TS-5', rowspan: 1 },
          { name: 'Akhir TS-4', rowspan: 1 },
          { name: 'Akhir TS-3', rowspan: 1 },
          { name: 'Akhir TS-2', rowspan: 1 },
          { name: 'Akhir TS-1', rowspan: 1 },
          { name: 'Akhir TS', rowspan: 1 },
        ],
      ])
      const table = createTable(header, rownum, body)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 9.1.2.4 Tracer Study, Waktu Tunggu Mendapatkan Pekerjaan Pertama',
    name_sheet: '9.1.2.4',
    endpoint: 'lkps_ttsr',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        d.tahun_lulus,
        d.jumlah_lulusan,
        d.jumlah_lulusan_yang_terlacak,
        d.wt_1,
        d.wt_2,
        d.wt_3,
      ])).join('')
      const rownum = createTableRowNum(6)
      const header = createTableRows([
        [
          { name: 'Tahun <br> Lulus' },
          { name: 'Jumlah <br> Lulusan' },
          { name: 'Jumlah Lulusan yang <br> Terlacak' },
          { name: 'Jumlah Lulusan Terlacak dengan Waktu Tunggu Mendapatkan Pekerjaan Pertama', colspan: 3, rowspan: 1 },
        ],
        [
          { name: 'WT < 6 Bulan', rowspan: 1 },
          { name: '6 ≤ WT ≤ 18 Bulan', rowspan: 1 },
          { name: 'WT > 18 Bulan', rowspan: 1 },
        ],
      ])
      const table = createTable(header, rownum, body)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 9.1.2.5 Tingkat Relevansi Pekerjaan',
    name_sheet: '9.1.2.5',
    endpoint: 'lkps_ttsr',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        d.tahun_lulus,
        d.jumlah_lulusan,
        d.jumlah_lulusan_yang_terlacak,
        d.wt_1,
        d.wt_2,
        d.wt_3,
      ])).join('')
      const rownum = createTableRowNum(6)
      const header = createTableRows([
        [
          { name: 'Tahun <br> Lulus' },
          { name: 'Jumlah <br> Lulusan' },
          { name: 'Jumlah Lulusan yang <br> Terlacak' },
          { name: 'Jumlah Lulusan Terlacak dengan Tingkat Relevansi Bidang Kerja', colspan: 3, rowspan: 1 },
        ],
        [
          { name: 'Tinggi', rowspan: 1 },
          { name: 'Sedang', rowspan: 1 },
          { name: 'Rendah', rowspan: 1 },
        ],
      ])
      const table = createTable(header, rownum, body)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 9.1.2.6 Tingkat Kepuasan Pengguna Lulusan',
    name_sheet: '9.1.2.6',
    endpoint: 'lkps_tkpl',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.jenis_kemampuan,
        d.sangat_baik,
        d.baik,
        d.cukup,
        d.kurang,
        d.rencana_tindak_lanjut_oleh_ps_dan_atau_upps,
      ])).join('')
      const rownum = createTableRowNum(7)
      const header = createTableRows([
        [
          { name: 'No.' },
          { name: 'Jenis Kemampuan' },
          { name: 'Tingkat Kepuasan Pengguna (%)', colspan: 4, rowspan: 1 },
          { name: 'Rencana Tidak Lanjut <br> oleh PS dan/atau UPPS' },
        ],
        [
          { name: 'Sangat Baik', rowspan: 1 },
          { name: 'Baik', rowspan: 1 },
          { name: 'Cukup', rowspan: 1 },
          { name: 'Kurang', rowspan: 1 },
        ],
      ])
      const table = createTable(header, rownum, body)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 9.2.2.1 Publikasi DTPS dan Mahasiswa',
    name_sheet: '9.2.2.1',
    endpoint: 'lkps_pidtps',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.jenis_publikasi,
        d.ts_1,
        d.ts_2,
        d.ts,
        d.jumlah,
      ])).join('')
      const rownum = createTableRowNum(6)
      const header = createTableRows([
        [
          { name: 'No.' },
          { name: 'Jenis Publikasi' },
          { name: 'Jumlah Judul', colspan: 3, rowspan: 1 },
          { name: 'Jumlah' },
        ],
        [
          { name: 'TS-1', rowspan: 1 },
          { name: 'TS-2', rowspan: 1 },
          { name: 'TS', rowspan: 1 },
        ],
      ])
      const table = createTable(header, rownum, body)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 9.2.2.2 Karya Ilmiah DTPS dan Mahasiswa yang Disitasi',
    name_sheet: '9.2.2.2',
    endpoint: 'lkps_kidtps',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.data_master_dosen.full_name,
        d.judul_artikel,
        d.jml_sitasi,
      ])).join('')
      const rownum = createTableRowNum(4)
      const header = createTableRow([
        'No.',
        'Nama Dosen/Mahasiswa',
        'Judul Karya Ilmiah, Tahun, Nama Jurnal/Prosiding/Buku, Nomor Halaman',
        'Jumlah Sitasi',
      ])
      const footer = createTableRows([
        [{ name: 'Jumlah', colspan: 3, rowspan: 1 }],
        [{ name: 'Rata-rata', colspan: 3, rowspan: 1 }],
      ])
      const table = createTable(header, rownum, body, footer)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 9.2.2.3 Produk atau Jasa DTPS dan Mahasiswa yang Diadopsi oleh Masyarakat',
    name_sheet: '9.2.2.3',
    endpoint: 'lkps_pjdmy',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.data_master_dosen.full_name,
        d.nama_produk_jasa,
        d.deskripsi_produk_jasa,
        d.bukti,
      ])).join('')
      const rownum = createTableRowNum(5)
      const header = createTableRow([
        'No.',
        'Nama Dosen/Mahasiswa',
        'Nama Produk/Jasa',
        'Deskripsi Produk/Jasa',
        'Bukti',
      ])
      const footer = createTableRows([
        [{ name: 'Jumlah', colspan: 3, rowspan: 1 }],
        [{ name: 'Rata-rata', colspan: 3, rowspan: 1 }],
      ])
      const table = createTable(header, rownum, body, footer)
      return table
    },
  },
  {
    id: uuid(),
    name: 'Tabel 9.2.2.4 Produk atau Jasa DTPS dan Mahasiswa yang Ber-HKI atau Paten',
    name_sheet: '9.2.2.4',
    endpoint: 'lkps_pjdmy',
    formatter: data => {
      const body = data.map((d, index) => createTableRow([
        index + 1,
        d.data_master_dosen.full_name,
        d.nama_produk_jasa,
        d.deskripsi_produk_jasa,
        d.bukti,
      ])).join('')
      const rownum = createTableRowNum(4)
      const header = createTableRow([
        'No.',
        'Nama Dosen/Mahasiswa',
        'Nama Produk/Jasa',
        'Deskripsi Produk/Jasa',
        'Bukti',
      ])
      const footer = createTableRows([
        [{ name: 'Jumlah', colspan: 3, rowspan: 1 }],
        [{ name: 'Rata-rata', colspan: 3, rowspan: 1 }],
      ])
      const table = createTable(header, rownum, body, footer)
      return table
    },
  },
]

export default tables
